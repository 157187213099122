.data-scale-form-group {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 1px 0px rgba(32, 54, 71, 0.7);
}

.data-scale-form-item {
  margin-top: 0px;
  margin-bottom: 0px;

}

.legend {
  text-align: center;
}