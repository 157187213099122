.customers-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 100px;
  margin-left: 50px;
}

.customer-details-item {
  width: 250px;
  height: 120px;
  border-radius: 10px;
  background: #ffffff;
  position: relative;
  box-shadow: 0px 1px 1px 1px rgba(53, 37, 37, 0.2);
}

.customer-details-item-title {
  position: absolute;
  top: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 700;
  color: gray;
}

.red {
  color:  rgba(255, 10, 10, 0.8);
}

.blue {
  color: #3989c6
}

.green {
  color: green
}

.customer-details-item-amount{
  position: absolute;
  bottom: 20%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;font-size: 225px25px25px5px;
  font-weight: 700;
  font-size: 22px;

  
}