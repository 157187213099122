.table-container {
  margin-top: 20px;
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
  margin-top: 10px;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  &.read {
    grid-template-columns: repeat(2, 1fr);
  }
}

.table-header-buttons {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.table-export-search {
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: auto;
}

.export-csv-link {
  margin-top: 10px;
}

.table .thead {
  /* These styles are required for a scrollable body to align with the header properly */
  overflow-y: auto;
  overflow-x: auto;
  background-color: rgba(32, 54, 71, 0.8);
  color: #fff;
  border-radius: 10px 10px 0 0;
}

.thead > .tr > .th {
  height: 50px;
  font-weight: 700;
}

.tbody > .tr > .td {
  align-items: center !important;
  font-size: 1rem;
  min-height: 50px;
}

.table .tbody {
  /* These styles are required for a scrollable table body */
  overflow-y: scroll;
  overflow-x: scroll;
  height: 400px;
}

.table .tbody .tr,
.td {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
}

.table .tbody .tr:hover {
  background-color: rgba(255, 255, 255, 0.902);

  box-shadow: 1px 1px 5px rgba(32, 54, 71, 0.7);
  cursor: pointer;
  &.selected {
    background-color: #c2dbff;
  }
}

.table .tbody .tr:nth-child(even) {
  background-color: rgba(242, 245, 245, 0.8);
  &.selected {
    background-color: #c2dbff;
  }
}

.table .tbody .tr :last-child .td {
  border-bottom: 0;
}

.table .th,
.td {
  margin: 0;
  padding: 0.25rem;
  // border: 1px solid #ccc;

  /* In this example we use an absolutely position resizer,
     so this is required. */
  position: relative;
}

.table .th:last-child,
.td:last-child {
  border-right: 0;
}

.table th,
td .resizer {
  right: 0;
  background: blue;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  /* prevents from scrolling while dragging on touch devices */
  touch-action: none;
}

.table th,
td .resizer .isResizing {
  background: red;
}

.btn-delete {
  border-radius: 5px;
  width: max-content;
  height: 40px;
  background-color: rgba(32, 54, 71, 0.8);
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
  border: 1px solid rgba(32, 54, 71, 1);
  &:hover {
    background-color: rgba(32, 54, 71, 1);
    color: #fff;
  }
}

.tr {
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
  &.selected {
    background-color: #c2dbff;
  }
}

.th {
  &.table-action {
    flex: none !important;
    width: 100px !important;
  }
}

.td {
  &.table-action {
    flex: none !important;
    width: 93px !important;
    justify-content: center !important;
  }
}

.table-action-icon {
  width: 30px;
  height: 30px;
  margin: 2px;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    background-color: #8080806b;
    border-radius: 10px;
  }
}

// .payment-card {
//   padding: 5px;
//   border: 1px solid;
//   border-radius: 15px;
//   background: #188038;
//   color: #fff;
// }

// .cash {
//   padding: 5px;
//   border: 1px solid;
//   border-radius: 15px;
//   background: #4338CA;
//   color: #fff
// }

// .notBuy {
//   padding: 5px;
//   border: 1px solid;
//   border-radius: 15px;
//   color: #fff;
//   background: #d93025;
// }
