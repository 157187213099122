// @import "sales.scss";
// @import "suppliers.css";
// @import "products.css";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ZCOOL+XiaoWei&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "dashboard";
// @import "~bootstrap/dist/css/bootstrap.min.css";
// @import "~react-perfect-scrollbar/dist/css/styles.css";
@import "table.scss";
// @import "~react-datepicker/dist/react-datepicker.css";
@import "mainview.scss";
// @import "sidebar.scss";
// @import "customers.scss";
// @import "purchases.scss";
// @import "stock.scss";
@import "invoice.scss";
@import "purchasedetails.scss";
// @import "productform.scss";
// @import "proforma.scss";
@import "datascaleform.scss";
// @import "salesresult.scss";
@import "customersdetails.scss";
// @import "expenses.scss";
// @import "account.scss";

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  background-color: #F5F4FF !important;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
