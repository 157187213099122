.content {
  width: 95%;
}

.mainview-container {
  width: 100%;
  margin-top: 60px;
}

.account-menu {
  border-radius: 5px;
  position: fixed;
  right: 10px;
}
