.invoice header {
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #3989c6;
}

.invoice .company-details {
  text-align: right;
}

.invoice .company-details .name {
  margin-top: 0;
  margin-bottom: 0;
}

.invoice .contacts {
  margin-bottom: 20px;
}

.invoice .invoice-to {
  text-align: left;
}

.invoice .invoice-to .to {
  margin-top: 0;
  margin-bottom: 0;
}

.invoice .invoice-details {
  text-align: right;
}

.invoice .invoice-details .invoice-id {
  margin-top: 0;
  color: #3989c6;
}

.invoice main {
  padding-bottom: 50px;
}

.invoice table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 2%;
}

.invoice table td,
.invoice table th {
  padding-left: 1%;
  padding-right: 1%;
  background: #eee;
  border-bottom: 1px solid #fff;
}

.invoice table th {
  white-space: nowrap;
  font-weight: 200;
  font-size: 12px;
  color: black;
}

.invoice table td h3 {
  margin: 0;
  font-weight: 200;
  color: #3989c6;
  font-size: 12px;
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit,
.invoice table .sumarize {
  text-align: right;
  font-size: 12px;
}

.invoice table .no {
  color: #fff;
  font-size: 1.6em;
  background: #3989c6;
}

.invoice table .unit {
  background: #ddd;
}

.invoice table .total {
  background: #3989c6;
  color: #fff;
}

.invoice table tbody tr:last-child td {
  border: none;
}

.invoice table tfoot td {
  background: 0 0;
  border-bottom: none;
  white-space: nowrap;
  text-align: right;
  // padding: 10px 20px;
  font-size: 1.2em;
  border-top: 1px solid #aaa;
}

.invoice table tfoot tr:first-child td {
  border-top: none;
}

.invoice table tfoot tr:last-child td {
  color: #3989c6;
  font-size: 12px;
  border-top: 1px solid #3989c6;
}

.invoice table tfoot tr td:first-child {
  border: none;
}

.invoice .bottom {
  border-bottom: 1px solid;
  width: 35%;
  margin-left: 65%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  font-size: 1.2em;
  font-weight: 400;
}

.invoice .bottom .remaind {
  color: #3989c6;
  font-size: 12px;
}

.invoice {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subinvoice {
  padding: 1cm;
  border: 5px red solid;
  height: 257mm;
  outline: 2cm #FFEAEA solid;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
      width: 210mm;
      height: 297mm;        
  }
  .invoice {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
      font-size: 11px !important;
      overflow: hidden !important;
  }


  .invoice > div:last-child {
    page-break-before: always;
  }
}


.invoice {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.invoice-download-btn {
  position: sticky;
  top: 30px;
  left: 80%;
}