.purchase-details-container{
  margin: 10px;
}

.purchase-products-container{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
}

.purchase-product-detail{
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: 60px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  background-color:#fff;
  box-shadow: 1px 1px 5px #555;
  margin-bottom: 15px;
}

.purchase-product-detail-header{
  display: flex;
  justify-content: space-around;
  width: 80%;
  height: 100px;
  margin-right: auto;
  margin-left: auto;
}

.purchase-product-detail-item{
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  font-weight: bold;
}

.purchase-product-header-item {
  width: 110px;
  height: 50px;
  justify-content: center;
  border-radius: 20px;
  background-color: #4338CA;
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  color: #fff;
  font-weight: bold;
}

.purchase-total-price{
  display: flex;
  justify-content: space-between;
  width: 300px;
  align-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 25px;
  border-radius: 50px;
  background-color: #188038;
  padding: 10px 20px 10px 20px;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

.purchase-supplier-info{
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.purchase-supplier-name{
  font-size:xx-large;
  font-weight: bold;
  letter-spacing: .3rem;
}

.purchase-supplier-address{
  font-size: large;
  font-weight: 300;
}
.purchase-supplier-email{
  font-size: large;
  font-weight: 300;
}

.purchase-supplier-tel{
  font-size: large;
  font-weight: 300;
}

.purchase-product-item-container{
  width: 20%;
  display: flex;
  justify-content: center;
}